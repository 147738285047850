import {Component, OnInit, ViewChild} from "@angular/core";
import {deliveryColumnConfig, deliveryDisplayedColumns} from "../delivery/delivery-table";
import {Article, Delivery, Trunk, User, Vendor} from "@knust/api-interfaces";
import {Router} from "@angular/router";
import {
  changeReportsCompletedFilter,
  loadDeliveryReports,
  setDeliveryReportsPage,
  setDeliveryReportsSort,
  setDeliveryReportsTerm,
  setReportsArticleFilter,
  setReportsDateFilter,
  setReportsUserFilter,
  setReportsVendorFilter
} from "../delivery/+state/delivery.actions";
import {ColumnConfig} from "../reusable-components/data-table/column-config";
import {
  getAllDeliveryReports,
  getDeliveryReportsPage,
  getDeliveryReportsSort,
  getDeliveryReportsTerm,
  getDeliveryReportsTotal,
  getReportsCompletedFilter
} from "../delivery/+state/delivery.selectors";
import {Store} from "@ngrx/store";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {FormBuilder, FormControl} from "@angular/forms";
import {debounceTime, filter, ReplaySubject, take} from "rxjs";
import {UserService} from "../utility/services/user.service";
import {ArticleService} from "../utility/services/article.service";
import {VendorService} from "../utility/services/vendor.service";
import {MatDatepicker} from "@angular/material/datepicker";
import {trunkColumnConfig, trunkDisplayedColumns} from "../trunks/trunks-table";
import {
  changeTrunkReportsCompletedFilter,
  loadTrunkReports,
  setTrunkReportsArticleFilter,
  setTrunkReportsDateFilter,
  setTrunkReportsPage,
  setTrunkReportsSort,
  setTrunkReportsTerm,
  setTrunkReportsUserFilter
} from "../trunks/+state/trunk.actions";
import {
  getAllTrunkReports,
  getTrunkReportsPage,
  getTrunkReportsSort,
  getTrunkReportsTerm,
  getTrunkReportsTotal
} from "../trunks/+state/trunk.selectors";

@UntilDestroy()
@Component({
  selector: 'knust-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  @ViewChild('picker') picker!: MatDatepicker<Date | undefined>;

  deliveryReportsDisplayedColumns = [...deliveryDisplayedColumns, 'totalPrice', 'articleCount', 'expand'];
  deliveryReportsColumnConfig: ColumnConfig[] = [
    ...deliveryColumnConfig,
    {
      name: 'Gesamtpreis',
      refName: 'totalPrice',
      suffix: '€'
    },
    {
      name: 'Artikelpositionen',
      refName: 'articleCount',
    }
  ];
  deliveryReportsCommandMap = {
    getAll: getAllDeliveryReports,
    getTotal: getDeliveryReportsTotal,
    getPage: getDeliveryReportsPage,
    setPage: setDeliveryReportsPage,
    getTerm: getDeliveryReportsTerm,
    setTerm: setDeliveryReportsTerm,
    getSort: getDeliveryReportsSort,
    setSort: setDeliveryReportsSort,
    load: loadDeliveryReports
  };

  trunkReportsDisplayedColumns = [...trunkDisplayedColumns, 'createdByUser', 'articleCount', 'expand'];
  trunkReportsColumnConfig: ColumnConfig[] = [
    ...trunkColumnConfig,
    {
      name: 'Artikelpositionen',
      refName: 'articleCount',
    },
  ];
  trunkReportsCommandMap = {
    getAll: getAllTrunkReports,
    getTotal: getTrunkReportsTotal,
    getPage: getTrunkReportsPage,
    setPage: setTrunkReportsPage,
    getTerm: getTrunkReportsTerm,
    setTerm: setTrunkReportsTerm,
    getSort: getTrunkReportsSort,
    setSort: setTrunkReportsSort,
    load: loadTrunkReports
  };

  showCompletedDeliveries = false;
  showCompletedTrunks = false;

  userFilterCtrl: FormControl = new FormControl();
  userSearchTerm = '';
  public filteredUsers: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);

  articleFilterCtrl: FormControl = new FormControl();
  articleSearchTerm = '';
  public filteredArticles: ReplaySubject<Article[]> = new ReplaySubject<Article[]>(1);

  vendorFilterCtrl: FormControl = new FormControl();
  vendorSearchTerm = '';
  public filteredVendors: ReplaySubject<Vendor[]> = new ReplaySubject<Vendor[]>(1);

  dateStart: Date | undefined;
  dateEnd: Date | undefined;

  constructor(private router: Router,
              private fb: FormBuilder,
              private userService: UserService,
              private articleService: ArticleService,
              private vendorService: VendorService,
              private store: Store) {
    this.store.select(getReportsCompletedFilter).pipe(
      untilDestroyed(this)
    ).subscribe(completedFilter => {
      this.showCompletedDeliveries = completedFilter;
    });

    // Initialisierung des Nutzer-Dropdowns
    this.userService.allUsers.pipe(
      filter(users => users.length > 0),
      take(1)
    ).subscribe(() => this.filterUsers());

    this.filterArticles();
    this.filterVendors();
  }

  ngOnInit() {
    this.userFilterCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(value => value !== this.userSearchTerm)
      )
      .subscribe(() => {
        this.filterUsers();
      });

    this.articleFilterCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        filter(value => value !== this.articleSearchTerm)
      )
      .subscribe(() => {
        this.filterArticles();
      });

    this.vendorFilterCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        filter(value => value !== this.vendorSearchTerm)
      )
      .subscribe(() => {
        this.filterVendors();
      });
  }

  filterUsers() {
    this.userSearchTerm = this.userFilterCtrl.value ?? '';

    const filteredUsers = this.userService.allUsers.value.filter(user => {
      const fullName = user.person.firstName.toLowerCase() + user.person.lastName.toLowerCase();
      return fullName.includes(this.userSearchTerm.toLowerCase());
    })

    this.filteredUsers.next(filteredUsers);
  }

  filterArticles() {
    this.articleSearchTerm = this.articleFilterCtrl.value ?? '';

    this.articleService.loadArticles('?page=1&limit=100&search=' + this.articleSearchTerm.toLowerCase()).subscribe(
      res => this.filteredArticles.next(res.items)
    );
  }

  filterVendors() {
    this.vendorSearchTerm = this.vendorFilterCtrl.value ?? '';

    this.vendorService.loadVendors('?page=1&limit=100&search=' + this.vendorSearchTerm.toLowerCase()).subscribe(
      res => this.filteredVendors.next(res.items)
    );
  }

  resetDatepicker() {
    this.picker.select(undefined);
    this.picker.close();
    this.dateStart = undefined;
    this.dateEnd = undefined;

    this.setDeliveriesDateFilter();
  }

  navigateToDelivery(delivery: Delivery) {
    this.router.navigate(['wareneingangsbuchung', delivery.id])
  }

  setDeliveriesCompletedFilter() {
    this.store.dispatch(changeReportsCompletedFilter());
  }

  setDeliveriesUserFilter(userId: number) {
    this.store.dispatch(setReportsUserFilter({ userId }));
  }

  setDeliveriesArticleFilter(articleId: number) {
    this.store.dispatch(setReportsArticleFilter({ articleId }));
  }

  setDeliveriesVendorFilter(vendorId: number) {
    this.store.dispatch(setReportsVendorFilter({ vendorId }));
  }

  setDeliveriesDateStartFilter(dateStart: Date) {
    dateStart.setHours(12);
    this.dateStart = dateStart;
    this.setDeliveriesDateFilter();
  }

  setDeliveriesDateEndFilter(dateEnd: Date) {
    dateEnd.setHours(12);
    this.dateEnd = dateEnd;
    this.setDeliveriesDateFilter();
  }

  setDeliveriesDateFilter() {
    this.store.dispatch(setReportsDateFilter({ dateStart: this.dateStart, dateEnd: this.dateEnd }));
  }

  exportDeliveryReports() {
    this.store.dispatch(loadDeliveryReports({ download: true }));
  }

  // Methoden für Entnahmen
  navigateToTrunk(trunk: Trunk) {
    this.router.navigate(['entnahmen', trunk.id])
  }

  setTrunksCompletedFilter() {
    this.store.dispatch(changeTrunkReportsCompletedFilter());
  }

  setTrunksUserFilter(userId: number) {
    this.store.dispatch(setTrunkReportsUserFilter({ userId }));
  }

  setTrunksArticleFilter(articleId: number) {
    this.store.dispatch(setTrunkReportsArticleFilter({ articleId }));
  }

  setTrunksDateStartFilter(dateStart: Date) {
    dateStart.setHours(12);
    this.dateStart = dateStart;
    this.setTrunksDateFilter();
  }

  setTrunksDateEndFilter(dateEnd: Date) {
    dateEnd.setHours(12);
    this.dateEnd = dateEnd;
    this.setTrunksDateFilter();
  }

  setTrunksDateFilter() {
    this.store.dispatch(setTrunkReportsDateFilter({ dateStart: this.dateStart, dateEnd: this.dateEnd }));
  }

  exportTrunkReports() {
    this.store.dispatch(loadTrunkReports({ download: true }));
  }
}
