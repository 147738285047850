import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {ArticlesRoutingModule} from "./articles-routing.module";
import {DataTableModule} from "../reusable-components/data-table/data-table.module";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import * as fromArticle from "./+state/article.reducer";
import {ArticleEffects} from "./+state/article.effects";
import {ArticlesIndexComponent} from "./articles-index/articles-index.component";
import {ArticlesDetailsComponent} from "./articles-details/articles-details.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {VendorArticlesModule} from "../reusable-components/vendor-articles/vendor-articles.module";
import {
  ArticleStorageLocationsModule
} from "../reusable-components/article-storage-locations/article-storage-locations.module";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {ReserveArticleDialogComponent} from "./reserve-article-dialog/reserve-article-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {ScanArticleDialogComponent} from "./scan-article-dialog/scan-article-dialog.component";
import {ZXingScannerModule} from "@zxing/ngx-scanner";

@NgModule({
  declarations: [
    ArticlesIndexComponent,
    ArticlesDetailsComponent,
    ReserveArticleDialogComponent,
    ScanArticleDialogComponent,
  ],
    imports: [
        CommonModule,
        ArticlesRoutingModule,
        DataTableModule,
        StoreModule.forFeature(
            fromArticle.ARTICLE_FEATURE_KEY,
            fromArticle.reducer
        ),
        EffectsModule.forFeature([ArticleEffects]),
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        VendorArticlesModule,
        ArticleStorageLocationsModule,
        NgxMatSelectSearchModule,
        MatDialogModule,
        MatAutocompleteModule,
        ZXingScannerModule,
    ],
})
export class ArticlesModule {}
