import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {VendorArticlesComponent} from "./vendor-articles.component";
import {VendorArticleDialogComponent} from "./vendor-article-dialog/vendor-article-dialog.component";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {DataTableModule} from "../data-table/data-table.module";
import {MatSelectModule} from "@angular/material/select";
import {StoreModule} from "@ngrx/store";
import * as fromVendorArticle from "./+state/vendor-article.reducer";
import {EffectsModule} from "@ngrx/effects";
import {VendorArticleEffects} from "./+state/vendor-article.effects";
import {MatIconModule} from "@angular/material/icon";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {ConfirmModule} from "../confirm/confirm.module";

@NgModule({
  declarations: [
    VendorArticlesComponent,
    VendorArticleDialogComponent
  ],
    imports: [
      CommonModule,
      MatInputModule,
      ReactiveFormsModule,
      MatDialogModule,
      MatButtonModule,
      DataTableModule,
      MatSelectModule,
      StoreModule.forFeature(fromVendorArticle.VENDOR_ARTICLE_FEATURE_KEY, fromVendorArticle.reducer),
      EffectsModule.forFeature([VendorArticleEffects]),
      MatIconModule,
      MatCheckboxModule,
      NgxMatSelectSearchModule,
      ConfirmModule
    ],
  exports: [
    VendorArticlesComponent
  ]
})
export class VendorArticlesModule {}
