import {AbstractIndexCommands} from "../../utility/abstract-index/abstract-index-commands";
import {VendorArticle} from "@knust/api-interfaces";
import {
  getAllVendorArticle,
  getVendorArticlePage,
  getVendorArticleSort,
  getVendorArticleTerm,
  getVendorArticleTotal
} from "./+state/vendor-article.selectors";
import {
  createVendorArticle,
  deleteVendorArticle,
  loadVendorArticles,
  restoreVendorArticle,
  setVendorArticlePage,
  setVendorArticleSort,
  setVendorArticleTerm
} from "./+state/vendor-article.actions";

export const vendorArticlesCommandMap: AbstractIndexCommands<VendorArticle> = {
  getAll: getAllVendorArticle,
  getTotal: getVendorArticleTotal,
  getPage: getVendorArticlePage,
  setPage: setVendorArticlePage,
  getTerm: getVendorArticleTerm,
  setTerm: setVendorArticleTerm,
  getSort: getVendorArticleSort,
  setSort: setVendorArticleSort,
  load: loadVendorArticles,
  createElement: createVendorArticle,
  deleteElement: deleteVendorArticle,
  restoreElement: restoreVendorArticle,
}

export const vendorArticlesDisplayedColumns = [
    'id',
    'vendorArticleNumber',
    'vendor.organization.name',
    'manufacturerName',
    'isMainVendorArticle',
    'packagingUnit',
    'price'
  ];
export const vendorArticlesColumnConfig = [
  {
    name: 'ID',
    refName: 'id'
  },
  {
    name: 'Lieferanten-Artikelnummer',
    refName: 'vendorArticleNumber'
  },
  {
    name: 'Lieferant',
    refName: 'vendor',
    nestedName: ['organization', 'name'],
    useNestedName: true,
  },
  {
    name: 'Hersteller',
    refName: 'manufacturerName'
  },
  {
    name: 'Hauptlieferant',
    refName: 'isMainVendorArticle',
    type: 'boolean',
    trueText: 'ja',
    falseText: 'nein'
  },
  {
    name: 'VPE',
    refName: 'packagingUnit'
  },
  {
    name: 'Preis',
    refName: 'price',
    suffix: '€'
  }
];
