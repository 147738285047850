import {LOCALE_ID, NgModule} from "@angular/core";
import {CommonModule, DATE_PIPE_DEFAULT_TIMEZONE, registerLocaleData} from "@angular/common";
import { CoreComponent } from './core.component';
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule, MatIconRegistry} from "@angular/material/icon";
import {MAT_DATE_LOCALE, MatLineModule, MatNativeDateModule} from "@angular/material/core";
import {MatListModule} from "@angular/material/list";
import {MatSidenavModule} from "@angular/material/sidenav";
import {NotificationModule} from "./reusable-components/notification/notification.module";
import {RouterLinkActive, RouterLinkWithHref, RouterOutlet} from "@angular/router";
import {MatBadgeModule} from "@angular/material/badge";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TrunksModule} from "./trunks/trunks.module";
import {UsersModule} from "./users/users.module";
import {StoreModule} from "@ngrx/store";
import {reducers} from "./+state/root.reducer";
import {environment} from "environments/environment";
import {StoreRouterConnectingModule} from "@ngrx/router-store";
import {CustomRouteSerializer} from "./+state/custom-route.serializer";
import {EffectsModule} from "@ngrx/effects";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {HttpErrorInterceptor} from "./utility/interceptors/http-error.interceptor";
import {AuthInterceptor} from "./utility/interceptors/auth.interceptor";
import {getGermanPaginatorIntl} from "./utility/localization/german-paginator-intl";
import {PendingChangesGuard} from "./utility/guards/pending-changes.guard";
import localeDe from "@angular/common/locales/de";

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatLineModule,
    MatListModule,
    MatSidenavModule,
    MatBadgeModule,
    NotificationModule,
    RouterLinkActive,
    RouterLinkWithHref,
    RouterOutlet,
    HttpClientModule,
    BrowserAnimationsModule,
    TrunksModule,
    UsersModule,
    StoreModule.forRoot(
      reducers,
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true
        }
      }
    ),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouteSerializer
    }),
    EffectsModule.forRoot([]),
    [environment.production ? [] : StoreDevtoolsModule.instrument()],
    NotificationModule,
    MatNativeDateModule,
  ],
  declarations: [CoreComponent],
  exports: [
    CoreComponent
  ],
  providers: [
    PendingChangesGuard,
    {provide: DATE_PIPE_DEFAULT_TIMEZONE, useValue: ('GMT+' + (new Date().getTimezoneOffset() / 60 * -1))},
    {provide: LOCALE_ID, useValue: "de-DE"},
    {provide: MAT_DATE_LOCALE, useValue: "de-DE"},
    {provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl()},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
  ],
})
export class CoreModule {
  constructor(iconRegistry: MatIconRegistry) {
    registerLocaleData(localeDe);
    iconRegistry.setDefaultFontSetClass('material-icons-sharp');
  }
}
